.esgrbanerSection {
  /* background-image: url(../../img/esgreportg1.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
  position: relative;
  overflow: hidden;
  z-index: -1;
}

.esgrbanerSection h1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 240px 0;
  text-align: center;
  font-size: 4.6666666666667rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.supplybanerSection {
  /* background-image: url(../../img/supplychain12.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
  position: relative;
  overflow: hidden;
  z-index: -1;
}

.words_back .container {
  display: flex;
  align-items: center;
  height: 100%;
}

.supplybanerSection h1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 240px 0;
  text-align: center;
  font-size: 4.6666666666667rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.carbonfootbanerSection {
  /* background-image: url(../../img/carbonfoot123.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
  position: relative;
  overflow: hidden;
  z-index: -1;
}

.carbonfootbanerSection h1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 240px 0;
  text-align: center;
  font-size: 4.6666666666667rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}
.First-para h5 {
  line-height: 1.2em;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 700;
  color: #1f9ed1;
  margin-bottom: 11px;
}

.First-para h2 {
  font-size: 2.2666666666667rem;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.2em;
  font-weight: 700;
}

.serve {
  padding: 70px 0 0;
  align-items: center;
}

.model_swction_tab h4 {
  padding: 15px 0;
  font-weight: 700;
}

img.pic24 {
  width: 100%;
  border-radius: 10px;
  height: 283px;
  object-fit: cover;
}

.carousel {
  position: relative;
  height: 219px;
}
.custom-title {
  text-align: center;
}
.move-para {
  margin-left: 1rem;
}
/* Default styles for all screen sizes */
.vedio-width {
  width: 80%; /* Default width for larger screens */
  border-radius: 0.8rem;
}

/* Responsive styles for small screens (up to 576px) */
@media (max-width: 776px) {
  .vedio-widt {
    width: 100%; /* Full width for small screens */
    border-radius: 0.8rem;
  }
}
