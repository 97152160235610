.blogbanerSection {
  background-image: url(../../img/blogbg.webp);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
}

.blogbanerSection h1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 240px 0;
  text-align: center;
  font-size: 4.6666666666667rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: white;
}
.blogpagebanerSection {
  background-image: url(../../img/blogdetailpage12.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
}

.blogpagebanerSection h2 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 240px 0;
  text-align: center;
  font-size: 4.6666666666667rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: white;
}
.blog-detail-section h4 {
  font-size: 15px;
  font-weight: 500;
  color: #1f9ed1;
  text-transform: uppercase;
}

.blog-detail-section p {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
}

.blog-detail-text p {
  font-size: 14px;
}
.inline {
  display: -webkit-inline-box;
}
.movement {
  margin-right: 0.2rem;
}
