@import '~boxicons/css/boxicons.min.css';

.banneroverlay {
    background-color: #011627d4;
}

.banneroverlay h1 {
    padding: 10px;
}

@media (max-width: 1400px) {

    /* .navbar-expand-xxl .navbar-toggler {
        display: none;
    } */
    .navbar-nav a {
        color: #000;
    }

    .offcanvas-body {
        background-color: #000;
    }
}

.navbar-toggler {
    background-color: #fff;
    border-radius: 0;
}

.dropdown-menu {
    --bs-dropdown-bg: #333 !important;
}

.dropdown-item:hover {
    background-color: transparent !important;
}

.drop.dropdown button {
    background: transparent;
    border: none;
    color: #fff;
    padding: 8px 0;
}

.drop.dropdown button:hover {
    background: transparent;
    border: none;
    color: #1f9ed1;
}

.btn:first-child:active {
    background-color: transparent;
}

.Privacy-12 {
    padding: 70px 0;
}

.Privacy-12 h2 {
    font-size: 17px;
    font-weight: 600;
}

.Privacy-12 p {
    font-size: 12px;
}

.blog_card {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 10px;
}

.carousel-caption {
    position: relative !important;
    left: 0% !important;
    right: 0% !important;
}

.carousel-control-next {
    display: none !important;
}

.carousel-control-prev {
    display: none !important;
}
.privacybanerSection {
    background-image: url(../../src/img/privacypic.jpeg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: white;
}

.privacybanerSection h1{
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
    padding: 240px 0;
    text-align: center;
    font-size: 3.6666666666667rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: white;
}
.termsbanerSection {
    background-image: url(../../src/img/termspic.webp);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: white;
}

.termsbanerSection h1{
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
    padding: 240px 0;
    text-align: center;
    font-size: 3.6666666666667rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: white;
}
.customerbanerSection {
    background-image: url(../../src/img/customerservices.webp);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: white;
}

.customerbanerSection h1{
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
    padding: 240px 0;
    text-align: center;
    font-size: 3.6666666666667rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: white;
}
.cancelbanerSection {
    background-image: url(../../src/img/refundpic.avif);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: white;
}

.cancelbanerSection h1{
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
    padding: 240px 0;
    text-align: center;
    font-size: 3.6666666666667rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: white;
}


.video-background {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

