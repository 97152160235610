.fresh_nature h4 {
  margin-bottom: 10px;
  font-size: 1.4666666666667rem;
  line-height: 1.2em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.esgframefbanerSection {
  /* background-image: url(../../img/reportingframe1.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
  position: relative;
  overflow: hidden;
  z-index: -1;
}

.words_back .container {
  display: flex;
  align-items: center;
  height: 100%;
}

.esgframefbanerSection h1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 240px 0;
  text-align: center;
  font-size: 4.6666666666667rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.carbonprintbanerSection {
  /* background-image: url(../../img/carbonprint1.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
  position: relative;
  overflow: hidden;
  z-index: -1;
}

.words_back .container {
  display: flex;
  align-items: center;
  height: 100%;
}

.carbonprintbanerSection h1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 240px 0;
  text-align: center;
  font-size: 4.6666666666667rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}
.sustainbanerSection {
  background-image: url(../../img/sustain543.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
}

.sustainbanerSection h1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 240px 0;
  text-align: center;
  font-size: 4.6666666666667rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}
.Proj {
  padding: 100px 0 35px;
}

.Proj_1 {
  padding: 25px 0 0;
}

.Extra {
  padding: 10px 0 150px 0;
}

.model_swction_tab a {
  /* text-decoration: none !important; */
  color: black;
}

.model_swction_tab1 {
  background-image: url(../../img/frameurl1.jpg);
  height: 300px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.model_swction_tab2 {
  background-image: url(../../img/frameurl2.jpg);
  height: 300px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.model_swction_tab3 {
  background-image: url(../../img/frameurl3.jpg);
  height: 300px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.model_swction_tab3 a,
.model_swction_tab2 a,
.model_swction_tab1 a {
  color: white;
  font-size: 30px;
  text-align: center;
}

.banneroverlays h2 {
  padding: 118px 0;
}

.banneroverlays {
  background-color: #213240ab;
}

.tab1 {
  background-color: #011627d4;
}

.Anchor_12 a {
  /* text-decoration: none !important; */
  color: black;
}

.sustain_123 h5 {
  color: #1f9ed1;
  font-size: 2rem;
}

.sustain_123 {
  padding: 15px;
}

.sustain_123 p {
  font-size: 0.9em;
}
.card-sustain {
  /* background-color: rgb(241, 233, 233); */
  padding: 2rem;
  border-radius: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}
.custom-row > .card-sustain {
  margin-bottom: 20px; /* Adjust the value to your preferred gap size */
}
.size {
  margin-top: 3rem;
  margin-bottom: 4rem;
}
@media (min-width: 992px) {
  .custom-margin-lg {
    margin-left: 3rem;
  }
}
