.banerSection {
  /* background-image: url(../../img/eshvideoback.gif); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
  position: relative;
  overflow: hidden;
  z-index: -1;
}

.words_back .container {
  display: flex;
  align-items: center;
  height: 100%;
}

.banerSection h1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 300px 0;
  text-align: center;
  font-weight: 700;
  line-height: 1.4em;
  font-size: 4.6666666666667rem;
  font-family: 'Montserrat', sans-serif;
}

.left h5 {
  color: white;
}

.welcome_test {
  font-size: 30px;
  color: white;
}

.banertextgroup {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 9px 0;
  text-align: center;
}

.Below-point {
  background-color: #011627;
  color: white;
  padding: 100px 30px 0px;
}

.Below-point h2 {
  font-weight: 400;
  margin-bottom: 1em;
  line-height: 1.5;
  font-size: 1.4rem;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
}

.Below-point p {
  margin-bottom: 1.75em;
  text-align: left;
  font-size: 15px;
}

.Slide {
  /* height: 100vh; */
  background-color: white;
  text-align: center;
  margin: 145px;
}

/* Adjust the class name if needed */
.Slide2 {
  position: relative;
  overflow: hidden;
}

.Slide2_back {
  position: relative;
  z-index: 1;
  background-color: #011627;
  opacity: 0.8;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

/* Add the following to make the content centered vertically */
.Slide2_back .container {
  display: flex;
  align-items: center;
  height: 100%;
}

.Slide2_back {
  background-color: #000000bd;
  padding: 100px 0;
}

.Slide2 h4 {
  margin-bottom: 10px;
  font-size: 1.4666666666667rem;
  line-height: 1.2em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: white;
}

.Slide2 h3 {
  font-size: 1.7333333333333rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: white;
}

.Slide2 p {
  color: white;
}

.W h2 {
  font-size: 34px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: white;
  line-height: 1.2em;
  padding-top: 0px;
}

.Slide3 {
  background-color: white;
  padding: 70px 0;
}

.Slide3 h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 34px;
}

.Slide3 h4 {
  color: #1f9ed1;
}

.Slide3 img {
  width: 370px;
  height: 180px;
}

.project_k h5 {
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 19px;
}

.project_k img {
  width: 401px;
}

.button-block {
  background-color: #1f9ed1;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  border: none;
  font-size: 15px;
  text-decoration: none;
}

.button-block:hover {
  background-color: #1f9ed1 !important;
  color: white !important;
}

.Slide h5 {
  font-size: 18px;
  font-size: 1.2rem;
  line-height: 1.2em;
  font-family: 'Montserrat', sans-serif;
  color: #011627;
  font-weight: 700;
}

.Slide h2 {
  color: #011627;
  font-size: 2.2666666666667rem;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.2em;
  font-weight: 700;
  margin-bottom: 50px;
}

.Quicklink-class a {
  color: white;
  text-decoration: none;
}

.Quicklink-class ul {
  padding: 0;
}

.Quicklink-class li {
  list-style: none;
  line-height: 32px;
}

.Quicklink-class a:hover {
  color: #1f9ed1;
}

.learn_more {
  background-color: #f9f8f8;
  background-position: center;
  gap: 40px;
  padding: 40px 0;
}

.learn_more h2 {
  margin: 0;
}

.navbar-brand {
  color: rgb(255 255 255) !important;
}

.navbar-nav a {
  color: #fff;
}

.navbar-nav a:hover {
  color: #1f9ed1;
}

.middle1Section {
  width: 100%;
  border-radius: 40px;
}

.middleLeftSection h1 {
  color: black;
  background-color: white;
  margin-top: 0px;
  margin-right: 0px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  text-transform: capitalize;
}

.firstMiddleSection {
  padding: 70px 0;
}

.mission {
  width: 420px;
  height: 420px;
  margin-left: 94px;
  margin-top: 24px;
}

.drop {
  color: #4586a2;
}

button.view_all {
  border: 2px solid #2488a8 !important;
  padding: 10px 10px;
  background-color: #ffffff00;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.middleLeftSection h4 {
  color: #1f9ed1;
}
.card-home {
  height: 232px;
  /* background-color: rgb(241, 233, 233); */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  padding: 28px 0;
  background-color: white !important;
}
