.aboutbanerSection {
  background-image: url(../../img/esg3.webp);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
}

.aboutbanerSection h1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 240px 0;
  text-align: center;
  font-size: 4.6666666666667rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: white;
}

/* .Nature_lover{
    padding: 150px 0;
} */
.AboutM h2 {
  margin-bottom: 10px;
  font-size: 2.2666666666667rem;
  line-height: 1.2em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin: 0;
  padding-right: 25px;
}

.AboutM h5 {
  line-height: 1.2em;
  margin: 0;
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  padding: 0;
  color: #1f9ed1;
  margin-bottom: 11px;
}

.Some-C {
  padding: 100px 0 50px;
}

.AboutR h3 {
  line-height: 1.5em;
  margin: 0;
  font-size: 1.7333333333333rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.AboutR p {
  margin-bottom: 1.6em;
}

.Cool_Facts h5 {
  line-height: 1.2em;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}

.Cool_Facts h2 {
  line-height: 1.2em;
  margin: 0;
  font-size: 2.2666666666667rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.Clients {
  text-align: center;
  font-size: 19px;
  font-weight: 400;
  line-height: 2.5;
}

.Counter {
  font-size: 69px;
  font-weight: 600;
  line-height: 1;
}

.Lorem3 h3 {
  line-height: 1.5em;
  margin: 0;
  font-size: 1.7333333333333rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  padding: 70px 10px 50px 0px;
}

.Nature h4 {
  margin-bottom: 10px;
  font-size: 1.4666666666667rem;
  line-height: 1.2em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.Nature h3 {
  margin-bottom: 10px;
  font-size: 1.7366666666667rem;
  line-height: 1.5em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin: 0;
}

.Nature p {
  padding: 7px 10px 0px 5px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
}

.Extra {
  padding: 10px 0 100px 0;
}

.card-look {
  height: 232px;
  /* background-color: rgb(241, 233, 233); */
  padding: 2rem;
  border-radius: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
