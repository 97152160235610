.academybanerSection{
    background-image: url(../../img/academy3.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: white;
}

.academybanerSection h1{
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
    padding: 240px 0;
    text-align: center;
    font-size: 3.6666666666667rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: white;
}
    