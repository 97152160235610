.contactbanerSection {
  background-image: url(../../img/esg2.webp);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
}

.contactbanerSection h1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 240px 0;
  text-align: center;
  font-size: 76px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  line-height: 1.4em;
  margin: 0;
  color: white;
}

.contactbanerSection span {
  font-size: 36px;
  font-weight: 300;
}

.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 100%;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 100%;
}

.gmap_iframe {
  height: 100% !important;
}

.Last_T {
  padding: 100px 0;
}

.List ul li {
  list-style: none;
}

.List ul li a {
  text-decoration: none;
  color: #000;
}

.List ul li a:hover {
  color: #1f9ed1;
}

.contact_card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 30px;
}
.title {
  color: rgb(0, 180, 241);
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  padding-top: 40px;
}
.para {
  margin: 15px auto 0 auto;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
  padding: 0 1rem 0 1rem;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s;
  margin-top: 20px;
  background-color: #f9f8f8;
  height: 14rem;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.3);
  border: none;
}

.card:hover {
  transform: scale(1.05);
}

.card h3 {
  margin: 15px;
  color: #333;
}

.request_imputs input,
.request_imputs select {
  height: 55px;
  font-size: 14px;
}

.request_imputs p {
  font-size: 28px;
}

.card p {
  margin: 0 15px 15px;
  color: #666;
}

@media (max-width: 767px) {
  .card {
    margin-top: 10px;
    width: 100%;
  }

  .card h3 {
    font-size: 1.2em;
  }

  .card p {
    font-size: 0.9em;
  }
  contact_card {
    height: 100%;
  }
}

.logo {
  font-size: 45px;
  color: #00b4f1;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #b3d1fa;
}

.contact-card {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.3);
  padding: 20px 0 30px 0;
}

.contact-card .info-address {
  font-size: 28px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact-card .info-address-data {
  padding: 0;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 0;
  text-align: center;
}
.link {
  color: black;
  text-decoration: none;
  font-size: 17px;
}

.link:hover {
  color: blue;
}
.social {
  font-size: 2rem;
}
