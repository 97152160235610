@media screen and (max-width: 480px) {
    h1 {
        font-size: 15px !important;
    }

    h2 {
        font-size: 14px !important;
    }

    h3 {
        font-size: 13px !important;
    }

    h4 {
        font-size: 12px !important;
    }

    h5 {
        font-size: 11px !important;
    }

    h6 {
        font-size: 10px !important;
    }

    p {
        font-size: 12px !important;
    }

    strong {
        font-size: 14px;
        font-weight: 700;
    }

    .d-flex a {
        display: block !important;
        margin-bottom: 15px;
        margin: 0 auto;
    }

    .con-Foot {
        margin-top: 20px;
    }

    .button-block {
        justify-content: center;
        font-size: 10px !important;
    }

    .carousel-item img {
        width: 75% !important;
    }

    .carousel-indicators {
        display: none;
    }

    .model_swction_tab1 a,
    .model_swction_tab2 a,
    .model_swction_tab3 a {
        font-size: 27px !important;
    }
}

@media (min-width: 480px) and (max-width: 768px) {

    h2 {
        font-size: 20px;
    }

    .aboutbanerSection h1 {
        font-size: 2.666667rem !important;
    }

    .con-Foot {
        padding: 10px;
    }

    .element.style {
        width: 153px;
    }

    .footjs img {
        width: 150px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .model_swction_tab1 a,
    .model_swction_tab2 a,
    .model_swction_tab3 a {
        font-size: 21px !important;
    }
}

@media (min-width: 991px) and (max-width: 1440px) {
    .AboutM {
        margin-bottom: 40px;
    }

    .model_swction_tab1 a,
    .model_swction_tab2 a,
    .model_swction_tab3 a {
        font-size: 28px !important;
    }
}