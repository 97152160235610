/* Navbars.css */

/* Styling for the 'active' class */
.navbar .nav-link.active {
  /* color: #fff;
  background-color: #007bff;
  border-radius: 0.5rem; */

  color: white;
  border-bottom: 1px solid white;
  font-weight: bolder;
}

.navbar-brand {
  font-weight: bold; /* Font weight for the navbar brand */
}

/* Customize other styles as needed */
